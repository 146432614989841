/* You can add global styles to this file, and also import other style files */

@import "~magnific-popup/dist/magnific-popup.css";
@import "~ion-rangeslider/css/ion.rangeSlider.css";
@import './assets/css/nice-select.css';
@import './assets/css/style.css';
@import './assets/css/responsive.css';
button:focus,
ngb-rating:focus {
	outline: none!important;
	box-shadow: none!important;
}
.slick-prev:before, .slick-next:before,
.slick-dots li button:before{
    display: none;
}
.slick-dots li button{
    padding: 0;
}
.slick-prev, .slick-next,
.slick-dots li{
    width: auto;
    height: auto;
    margin: 0;
}
.arrow-layout-2 .slick-prev{
    left: auto;
}
.arrow-layout-2 .slick-arrow:after{
    display: flex;
}
/* Pagination */
pagination-template .ngx-pagination {
	margin-top: 0px;
	justify-content: center;
	margin-bottom: 0;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: .25rem;
}
pagination-template .ngx-pagination li {
	margin: 0;
	position: relative;
}
pagination-template .ngx-pagination li.pagination-next,
pagination-template .ngx-pagination li.pagination-previous {
	padding: 0;
	display: block;
}
pagination-template .ngx-pagination li.pagination-next span,
pagination-template .ngx-pagination li.pagination-previous span,
pagination-template .ngx-pagination li a {
	background-color: #fff;
    font-weight: 600;
    padding: 8px 20px!important;
    margin: 0;
    position: relative;
    margin-left: 0;
    display: block;
    font-size: 14px;
    line-height: 1.5;
    border: 2px solid #eee;
    color: #020202;
    border-radius: 0;
}
pagination-template .ngx-pagination li.current {
	border-radius: 0px;
	color: #fff;
	border: 1px solid #ff0000;
	background-color: #ff0000;
	font-size: 14px;
	font-weight: 600;
	padding: 8px 20px!important;
	margin: 0;
	position: relative;
	display: block;
    line-height: 1.5;
}
.irs-handle i,
pagination-template .ngx-pagination li.pagination-next::after,
pagination-template .ngx-pagination li.pagination-next a:after,
pagination-template .ngx-pagination li.pagination-previous a::before,
pagination-template .ngx-pagination li.pagination-previous::before {
	display: none!important;
}
.thc-range-inner{
	margin-bottom: 5px;
}
.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
	background-color: transparent!important;
	color: #ffffff!important;
	font-size: 12px!important;
	font-weight: 600!important;
}
.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
	background: #ff0000!important;
}
.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
	border-top-color: #ff0000!important;
}
.irs--flat .irs-handle {
	border: none!important;
	width: 15px!important;
	height: 15px!important;
	top: 30px!important;
	background-color: #ff0000!important;
	cursor: pointer!important;
	border-radius: 50%;
}
.irs--flat .irs-bar {
	background-color: #ff0000!important;
}
.irs--flat .irs-line,
.irs--flat .irs-bar {
    top: 36px;
    height: 4px;
}
.coming-soon .coming-soon-text .counter .countdown {
    display: flex;
	flex-wrap: wrap;
    justify-content: center;
    margin: 0px 0;
}
.coming-soon .coming-soon-text .counter .countdown .measurements {
    text-align: center;
}
.coming-soon .coming-soon-text .counter .countdown .measurements>.measurements-number {
    width: 125px;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff0000;
    color: #fff;
    border-radius: 0px;
    margin: 0 15px 30px;
	font-size: 50px;
    line-height: 1;
    font-weight: 600;
}
.coming-soon .coming-soon-text .counter .countdown .measurements .measurements-text{
    color: #fff;
	display: none;
}
.banner-tabs .tab-pane [class^="col-"] .row, 
.banner-tabs .tab-pane [class*=" col-"] .row{
	margin: 0 -10px;
}
.main-banner.slick-dotted.slick-slider{
margin-bottom: 0;
}
.partner-box a,
.why-choose-box .why-choose-wrapper .why-choose-img>.row>div{
	cursor: auto;
}
.main-banner .slide-item{
	height: auto;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.main-banner .slide-item .slider-content-wrap{
	padding: 176px 0 320px;
}
.main-banner.slick-slider .slick-track, .main-banner.slick-slider .slick-list{
	display: flex;
}
.pagination_layout{
	margin-top: -30px;
}
.car-grid .car-grid-wrapper.car-grid .image-sec{
    height: 250px;
}
.car-grid .car-grid-wrapper.car-grid .image-sec img{
    object-fit: cover;
}